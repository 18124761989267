.label {
    color: var(--text-light, #A8927D);
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: 'nowrap';
    -webkit-text-stroke: 0.1px;
}

.optional {
    color: var(--text-dark, #E1D4C9);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}