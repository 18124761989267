/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content */
  .profile-content {
    width: 224px;
    height: 200px;
    background: white;
    border-radius: 24px;
    padding: 20px; /* Adjust as needed */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    align-items: center;
    margin: inherit;
    top: 24px;
    display: flex;
    flex-direction: column;
    
  }
  .profile-name {
    color: var(--text-light, #A8927D);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    top: 88px;
    position: absolute;
  }
  .profile-logout {
    bottom: 37px;
    position: absolute;
  }
