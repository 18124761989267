.clickable-item {
    color: var(--bs-link-color);
    text-decoration: none;
    color: var(--text-light, #A8927D);
    text-align: center;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.clickable-item:hover {
    color: var(--text-light, #A8927D);
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}

.breadcrumb-item.active {
    color: var(--Black, #000);
    text-align: center;
    font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.partner-info hr {
    display: none;
}