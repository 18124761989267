
/* Extra small devices */
@media only screen and (max-width: 576px) {
  .content {
    width: 100%;
  }
}

/* small devices */
@media only screen and (min-width: 576px) {
  .content {
    width: 100%;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content {
    width: 75%;
  }
  .table-responsive{
    height: 60vh;
  }
}

/* For orientation given in Figma and our laptops */
@media only screen and (min-width: 1440px)and (max-device-width: 1920px) {
  .content {
    width: 60%;
  }
  .table-responsive{
    height: 60vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .content {
    width: 35%;
  }
  .table-responsive{
    height: 60vh;
  }

}