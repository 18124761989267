@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap');

.title-font {
    font-size: 38px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    text-align: center;
    white-space: nowrap;
    color: var(--Sandrift, #a8927d);
    line-height: normal;
  }


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .title-font {
        font-size: 32px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .title-font{
        font-size: 34px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .title-font{
        font-size: 32px;
    }
}