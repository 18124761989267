.loader{
    width: 80%;
    padding-bottom: 15vh;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .loader{
        width: 85%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .loader{
        width: 70%;
    }
}
