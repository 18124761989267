
.list-grid {
    border-radius: 16px;
    height: 48px;  
    top: 139px;
  }

 
.nav-link-item {
    text-decoration:none;
    display:block;
    text-align: center;
    padding-top:10px;
    height:47px;
    color:#A8927D;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
}

.nav {
    padding-right: 0px;
}
.nav .active{
    color:#FFFFFF;
    background:linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%);
    border-radius: 24px;
}
