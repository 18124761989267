.close-success {
  cursor: pointer;
  color: black;
  font-size: 24px;
  color:#05E500
}
.close-danger {
  cursor: pointer;
  color: black;
  font-size: 24px;
  color:red
}
.info-bar-success  {
  width: 500%;
  height: 40px;
  background: linear-gradient(0deg, rgba(5, 230, 0, 0.1), rgba(5, 230, 0, 0.1)),
   linear-gradient(0deg, #FFFFFF, #FFFFFF);
  text-align: center;
  color: #05E500;
  padding-top: 0;
  font-size: 14px;
  border : none;
  position: fixed;
  top: 170px;
  left: 0;
  z-index: 1001;
}
.info-bar-danger  {
  width: 500%;
  height: 40px;
  background: #f8d7da;
  text-align: center;
  color: red;
  padding-top: 0;
  font-size: 14px;
  border : none;
  position: fixed;
  top: 170px;
  left: 0;
}
