@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap');

.header-container {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.main-header-container {
    max-width: 100%;
}

.portal-name {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    text-align: left;
    color: #A8927D;
    vertical-align: middle;
    white-space: nowrap;
}
.logo {
    max-width: 97px;
    max-height: 29px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .header-container{
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .header-container{
        margin-left: 2rem;
        margin-right: 1rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .header-container{
        margin-right: 2rem;
        margin-left: 2rem
    }
}