
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .smallcontent {
      width: 60%;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .smallcontent {
      width: 40%;
    }
  }

  @media only screen and (min-width: 1440px) {
    .smallcontent {
      width: 25%;
    }
  }

  .smallcontent {
    width: fit-content;
  }