.device-row {
    border-radius: 24px;
    background: var(--white, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.line {
    border: 1px solid #A8927D;
    opacity: 0.2;
    color: var(--Sandrift, #A8927D);
}