.gridview {
    margin: auto;
    border-radius: 24px;
    background: var(--White, #FFF);
    box-shadow: 0px 8px 48px 0px rgba(168, 146, 125, 0.12);
}

.gridview-header {
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    user-select: none;
}

.grid-text-align {
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 0px;
    width: 97%;
}