input.search {
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    top: 4px;
    left: 256px;
    gap: 0px;
    border-radius: 24px;
    border: 0px;
    display: block;
    background: var(--planar-light, rgba(168, 146, 125, 0.16)) url("src/images/search.png") no-repeat 13px center;
    background-size: 24px 24px;
    padding-left:70px;
  }

input.search:focus{
    outline: none;
}